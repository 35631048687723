<template>
  <div class="introduce">
    <div class="title" :style="styleObject">
      <p class="">{{ product.productName }}</p>
      <p class="">{{ product.position }}</p>
    </div>
    <div class="video_box" v-if="product.video">
      <video-player class="video-player vjs-custom-skin vjs-big-play-centered" ref="videoPlayer" :playsinline="true"
                    v-if="product.video" @play="onPlayerPlay($event)" :options="playerOptions"/>
    </div>
  </div>
</template>

<script>
import {videoPlayer} from "vue-video-player";
import 'video.js/dist/video-js.css';
import utils from "@/utils/utils";

export default {
  name: "suggest",
  components: {videoPlayer},
  props: {
    product: {
      type: Object
    }
  },
  watch: {
    product() {
      this.playerOptions.sources[0].src = utils.setOssUrl(this.product.video);
      this.styleObject = {
        backgroundImage: `url(${utils.setOssUrl(this.product.background)})`
      };
      // 判断是否是阿里云的视频，阿里云的截取封面，非阿里云自动获取。后续去掉
      if (this.product.video.includes("fileserver/save/file")) {
        this.playerOptions.poster = utils.setOssUrl(this.product.video) + "?x-oss-process=video/snapshot,t_100,f_jpg,w_0,h_0,m_fast";
      }
      // try {
      //   let img = new Image();
      //   img.src = this.playerOptions.poster;
      //   img.onload = () => {
      //     this.imgIsLoad = true;
      //   };
      // } catch (err) {
      //   this.imgIsLoad = true;
      // }
    }
  },
  data() {
    return {
      styleObject: {},
      imgIsLoad: false,
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: true, // 如果true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          src: this.product.video, // 路径
          type: 'video/mp4' // 类型
        }],
        poster: "", // 你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true,// 全屏按钮
          volumePanel: {inline: false}
        }
      }
    };
  },
  methods: {
    // 播放回调
    onPlayerPlay() {
      this.$sensors.officialSite_WatchVideo({
        officialSite_e_title: document.title
      });
    }
  },
};
</script>

<style scoped lang="scss">
.introduce {
  width: 100%;
  background-color: #FFFFFF;
  .title {
    display: flex;
    flex-direction: column;
    height: 422px;
    background-image: url("../../../assets/images/bg_default.jpg");
    background-position: center;
    background-size: cover;
    p {
      display: flex;
      justify-content: center;
      &:first-child {
        font-size: 48px;
        color: #FFFFFF;
        line-height: 67px;
        margin-top: 160px;
      }
      &:last-child {
        font-size: 28px;
        color: #FFFFFF;
        line-height: 40px;
        margin-top: 24px;
        padding:0 60px;
        text-align: center;
      }
    }
  }
  .video_box {
    margin: 0 auto;
    width: 630px;
    height: 354px;
    background: #FFFFFF;
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    opacity: 0.97;
    margin-top: -60px;
    video {
      width: 100%;
      height: 100%;
    }
    /deep/ .vjs-big-play-button {
      line-height: 1;
      border: none;
      width: 68px;
      height: 68px;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      margin-top: -34px;
      margin-left: -34px;
    }
    /deep/ .video-js .vjs-big-play-button .vjs-icon-placeholder {
      font-family: "font_family" !important;
      font-style: normal;
      color: #FFFFFF;
      font-size: 68px;
      &:before {
        font-family: "font_family" !important;
        font-style: normal;
        color: #FFFFFF;
        font-size: 68px;
        content: "\e61a";
      }
    }
  }
}
@media (min-width: 750px) and (max-width: 960px) {
  .introduce {
    width: 100%;
    background-color: #FFFFFF;
    .title {
      display: flex;
      flex-direction: column;
      height: 540px;
      background-image: url("../../../assets/images/bg_default.jpg");
      background-position: center;
      background-size: cover;
      p {
        display: flex;
        justify-content: center;
        &:first-child {
          font-size: 48px;
          color: #FFFFFF;
          line-height: 67px;
          margin-top: 186px;
        }
        &:last-child {
          font-size: 28px;
          color: #FFFFFF;
          line-height: 40px;
          margin-top: 24px;
        }
      }
    }
    .video_box {
      margin: 0 auto;
      width: 840px;
      height: 472px;
      background: #FFFFFF;
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      opacity: 0.97;
      margin-top: -77px;
      video {
        width: 100%;
        height: 100%;
      }
      /deep/ .vjs-big-play-button {
        line-height: 1;
        border: none;
        width: 90px;
        height: 90px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        margin-top: -45px;
        margin-left: -45px;
      }
      /deep/ .video-js .vjs-big-play-button .vjs-icon-placeholder {
        font-family: "font_family" !important;
        font-style: normal;
        color: #FFFFFF;
        font-size: 90px;
        &:before {
          font-family: "font_family" !important;
          font-style: normal;
          color: #FFFFFF;
          font-size: 90px;
          content: "\e61a";
        }
      }
    }
  }
}
@media (min-width: 961px) {
  .introduce {
    width: 100%;
    .title {
      display: flex;
      flex-direction: column;
      height: 630px;
      background-image: url("../../../assets/images/bg_default.jpg");
      background-position: center;
      background-size: cover;
      p {
        display: flex;
        justify-content: center;
        &:first-child {
          font-size: 56px;
          color: #FFFFFF;
          line-height: 78px;
          margin-top: 194px;
        }
        &:last-child {
          font-size: 24px;
          color: #FFFFFF;
          line-height: 33px;
          margin-top: 24px;
        }
      }
    }
    .video_box {
      margin: 0 auto;
      width: 660px;
      height: 371px;
      background: #FFFFFF;
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      margin-top: -238px;
      video {
        width: 100%;
        height: 100%;
      }
      /deep/ .vjs-big-play-button {
        width: 71px;
        height: 71px;
        margin-top: -35.5px;
        margin-left: -35.5px;
      }
      /deep/ .video-js .vjs-big-play-button .vjs-icon-placeholder {
        font-size: 71px;
        &:before {
          font-size: 71px;
        }
      }
    }
  }
}
</style>
