<template>
  <div class="price js_index_price">
    <p class="title">产品定价</p>
    <ul class="price_box" :class="{price_center:firmPrice.priceList.length===1}">
      <router-link :to="`/productPrice?id=${index}`" :class="`price_every price_every${index+1}`"
                   v-for="(item,index) in firmPrice.priceList"
                   :key="item.releaseName">
        <p class="eve_title">{{ item.releaseName }}</p>
        <div class="eve_pricelist">
          <p class="one_pr" v-if="item.pricingList.length===1">
            <span><label>￥</label>{{ item.pricingList[0].price }}</span>
            <span>{{ item.pricingList[0].unit }}</span>
          </p>
          <p class="eve_pr" v-else v-for="(p,i) in item.pricingList" :key="i">
            <span><label>￥</label>{{ p.price }}</span>
            <span>{{ p.unit }}</span>
          </p>
        </div>
        <p class="eve_desc js_eve_desc">{{ item.releaseProfile }}</p>
      </router-link>

    </ul>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "price",
  props: {
    firmPrice: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  created() {
  },
  mounted() {
    const el = $(".js_index_price");
    this.onResize(el);
  },
  methods: {
    onResize(el) {
      this.setArrWidt(el);
      window.onresize = () => {
        this.setArrWidt(el);
      };
    },
    setArrWidt(el) {
      el.find(".eve_pricelist").css("height", "auto");
      let maxWidth = 0;
      el.find(".eve_pricelist").each((e, dom) => {
        let wid = $(dom).outerHeight();
        if (wid > maxWidth) {
          maxWidth = wid;
        }
      });
      el.find(".eve_pricelist").css("height", maxWidth + "px");

      el.find(".js_eve_desc").css("height", "auto");
      let maxWidth2 = 0;
      el.find(".js_eve_desc").each((e, dom) => {
        let wid = $(dom).outerHeight();
        if (wid > maxWidth2) {
          maxWidth2 = wid;
        }
      });
      el.find(".js_eve_desc").css("height", maxWidth2 + "px");
    },
  }
};
</script>

<style scoped lang="scss">
.price {
  padding: 60px 0;
  padding-bottom: 0;
  .title {
    font-size: 40px;
    color: #333333;
    line-height: 56px;
    text-align: center;
    font-weight: bold;
  }
  .price_box {
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    margin-top: 49px;
    padding-bottom: 60px;
    .price_every {
      width: 467px;
      background-color: #FFFFFF;
      border-radius: 4px;
      margin-left: 60px;
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .eve_title {
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(108deg, #BDC9DC 0%, #8899B7 100%);
        height: 122px;
        font-size: 32px;
        color: #FFFFFF;
        flex-grow: 0;
        flex-shrink: 0;
        font-weight: bold;
      }
      .eve_pricelist {
        padding: 40px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .one_pr {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          span {
            &:first-child {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 54px;
              font-weight: bold;
              color: #FE5B02;
              line-height: 75px;
              label{
                font-size: 40px;
              }
            }
            &:last-child {
              font-size: 26px;
              font-weight: 400;
              color: #333333;
              line-height: 37px;
              margin-left: 32px;
            }
          }
        }
        .eve_pr {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 16px;
          &:first-child {
            margin-top: 0;
          }
          span {
            &:first-child {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              font-size: 36px;
              color: #FE5B02;
              width: 219px;
              text-align: right;
              font-weight: bold;
              label{
                font-size: 30px;
              }
            }
            &:last-child {
              font-size: 26px;
              color: #333333;
              width: 208px;
              text-align: left;
              margin-left: 32px;
            }
          }
        }
      }
      .eve_desc {
        font-size: 24px;
        color: #666666;
        line-height: 33px;
        padding: 0 40px 60px;
        text-align: center;
      }
    }
    .price_every1 {
    }
    .price_every2 {
      .eve_title {
        background: linear-gradient(90deg, #4E95F3 0%, #0056CB 100%);
      }
    }
    .price_every3 {
      .eve_title {
        background: linear-gradient(114deg, #405FB2 1%, #001F6E 100%);
      }
    }
    .price_every4 {
      .eve_title {
        background: linear-gradient(114deg, #605E69 0%, #252931 100%);
      }
    }
  }
  .price_center {
    justify-content: center;
  }
}
@media (min-width: 751px) and (max-width: 960px) {
  .price {
    padding: 60px 0;
    padding-bottom: 0;
    .title {
      font-size: 32px;
      color: #333333;
      line-height: 45px;
      text-align: center;
      font-weight: bold;
    }
    .price_box {
      display: flex;
      justify-content: flex-start;
      overflow-x: auto;
      margin-top: 60px;
      padding-bottom: 60px;
      .price_every {
        width: 408px;
        background-color: #FFFFFF;
        border-radius: 4px;
        margin-left: 24px;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:first-child{
          margin-left: 60px;
        }
        &:last-child{
          margin-right: 60px;
        }
        .eve_title {
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(108deg, #BDC9DC 0%, #8899B7 100%);
          height: 122px;
          font-size: 32px;
          color: #FFFFFF;
          flex-grow: 0;
          flex-shrink: 0;
          font-weight: bold;
        }
        .eve_pricelist {
          padding: 40px 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .one_pr {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            span {
              &:first-child {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 48px;
                font-weight: bold;
                color: #FE5B02;
                line-height: 67px;
                label{
                  font-size: 40px;
                }
              }
              &:last-child {
                font-size: 20px;
                font-weight: 400;
                color: #333333;
                line-height: 28px;
                margin-left: 16px;
              }
            }
          }
          .eve_pr {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            &:first-child {
              margin-top: 0;
            }
            span {
              &:first-child {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 30px;
                color: #FE5B02;
                width: 200px;
                text-align: right;
                font-weight: bold;
                label{
                  font-size: 26px;
                }
              }
              &:last-child {
                font-size: 26px;
                color: #333333;
                width: 177px;
                text-align: left;
                margin-left: 32px;
              }
            }
          }
        }
        .eve_desc {
          font-size: 20px;
          color: #666666;
          line-height: 28px;
          padding: 0 24px 60px;
          text-align: center;
        }
      }
      .price_every1 {
      }
      .price_every2 {
        .eve_title {
          background: linear-gradient(90deg, #4E95F3 0%, #0056CB 100%);
        }
      }
      .price_every3 {
        .eve_title {
          background: linear-gradient(114deg, #405FB2 1%, #001F6E 100%);
        }
      }
      .price_every4 {
        .eve_title {
          background: linear-gradient(114deg, #605E69 0%, #252931 100%);
        }
      }
    }
    .price_center {
      justify-content: center;
    }
  }
}
@media (min-width: 961px) {
  .price {
    padding: 60px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 32px;
      line-height: 45px;
      text-align: center;
    }
    .price_box {
      width: 1200px;
      display: flex;
      justify-content: center;
      overflow-x: hidden;
      margin-top: 40px;
      padding-bottom: 60px;
      .price_every {
        width: auto;
        max-width: 50%;
        flex: 1;
        background-color: #FFFFFF;
        border-radius: 0;
        margin-left: 24px;
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:hover{
          box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.07);
        }
        &:first-child {
          margin-left: 0;
        }
        .eve_title {
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(108deg, #BDC9DC 0%, #8899B7 100%);
          height: 122px;
          font-size: 28px;
          color: #FFFFFF;
          flex-grow: 0;
          flex-shrink: 0;
        }
        .eve_pricelist {
          padding: 40px 0;
          box-sizing: border-box;
          height: 100%;
          .one_pr {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            span {
              &:first-child {
                font-size: 44px;
                font-weight: bold;
                color: #FE5B02;
                line-height: 67px;
                label {
                  font-size: 30px;
                }
              }
              &:last-child {
                font-size: 22px;
                font-weight: 400;
                color: #333333;
                line-height: 28px;
                margin-left: 16px;
              }
            }
          }
          .eve_pr {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 16px;
            &:first-child {
              margin-top: 0;
            }
            span {
              &:first-child {
                text-align: right;
                font-size: 26px;
                color: #FE5B02;
                width: 1.68rem;
                label{
                  font-size: 22px;
                }
              }
              &:last-child {
                text-align: left;
                width: 1rem;
                font-size: 20px;
                color: #333333;
                margin-left: 16px;
              }
            }
          }
        }
        .eve_desc {
          font-size: 18px;
          color: #666666;
          line-height: 25px;
          padding: 0 12px 60px;
          text-align: center;
        }
      }
      .price_every2 {
        .eve_title {
          background: linear-gradient(90deg, #4E95F3 0%, #0056CB 100%);
        }
      }
      .price_every3 {
        .eve_title {
          background: linear-gradient(114deg, #405FB2 1%, #001F6E 100%);
        }
      }
      .price_every4 {
        .eve_title {
          background: linear-gradient(114deg, #605E69 0%, #252931 100%);
        }
      }
    }
  }
}
</style>
