<template>
  <div class="activity js_index_activity" :class="{padbot0:!mobMore}">
    <p class="title">市场活动</p>
    <div class="mob_small" v-show="!mobMore">
      <img class="small_img" :src="utils.setOssUrl(mobFrist.picture)" alt="">
      <div class="small_content">
        <p class="small_desc">{{ mobFrist.title }}</p>
        <p class="small_date">{{ mobFrist.operateTime }}</p>
      </div>
    </div>
    <p class="mob_more" v-show="!mobMore">
      <span @click="mobMoreClick">查看更多</span>
      <i @click="mobMoreClick" class="font_family icon-common_arrow_down"></i>
    </p>
    <div class="ac_box" v-show="mobMore">
      <div class="ac_pcleft">
        <a class="pcleft_link vishide animate__animated"
           :href="`/marketActivity/details?id=${pcLeft.moduleId}&moduleType=4`" target="_blank">
          <div class="left_img_box">
            <img class="left_img" :src="utils.setOssUrl(pcLeft.picture)" alt="">
          </div>
          <div class="mob_right">
            <p class="left_title">{{ pcLeft.title }}</p>
            <p class="left_desc">{{ pcLeft.digest }}</p>
            <p class="left_date">{{ pcLeft.operateTime }}</p>
          </div>
        </a>
      </div>
      <ul class="ac_ul">
        <li class="ac_one" v-for="item in pcRight" :key="item.moduleId">
          <a class="one_link vishide animate__animated" :href="`/marketActivity/details?id=${item.moduleId}&moduleType=4`" target="_blank">
            <div class="one_img_box">
              <img class="one_img" :src="utils.setOssUrl(item.picture)" alt="">
            </div>
            <div class="one_content">
              <p class="one_title">{{ item.title }}</p>
              <p class="one_desc">{{ item.digest }}</p>
              <p class="one_date">{{ item.operateTime }}</p>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <p class="more" v-show="mobMore">
      <dj-button type="dark" href="/marketActivity/list">查看更多</dj-button>
    </p>
  </div>
</template>

<script>
import utils from "@/utils/utils";
import $ from "jquery";

export default {
  name: "activity",
  props: {
    marketActivity: {
      type: Object,
      default: () => {
        return {
          activityList: []
        };
      }
    }
  },
  data() {
    return {
      mobMore: false,
      mobFrist: {},
      mobList: [],
      pcLeft: {},
      pcRight: [],
      utils,
    };
  },
  created() {
    // 处理时间格式
    for (let i of this.marketActivity.activityList) {
      i.operateTime = utils.getActivityDate(i.operateTime);
    }
    this.setActivityList();
  },
  mounted() {
    this.$nextTick(() => {
      this.handleScroll();
      this.removeVishide();
      window.addEventListener('scroll', this.handleScroll);
      window.onresize = () => {
        this.removeVishide();
      };
    });
  },
  methods: {
    mobMoreClick() {
      this.mobMore = true;
    },
    setActivityList() {
      if (this.marketActivity.activityList.length > 0) {
        this.pcLeft = this.marketActivity.activityList[0];
        let mobFrist = this.marketActivity.activityList.slice(0, 1);
        if (mobFrist.length > 0) {
          this.mobFrist = mobFrist[0];
        }
      }
      if (this.marketActivity.activityList.length > 1) {
        this.mobList = this.marketActivity.activityList.slice(1, this.marketActivity.activityList.length);
        this.pcRight = this.marketActivity.activityList.slice(1, this.marketActivity.activityList.length);
      }
    },
    handleScroll() {
      // pc端左边动画效果
      utils.handleScroll($(".js_index_activity .ac_pcleft"), (i, el) => {
        el.children(".pcleft_link").removeClass("vishide").addClass("animate__slideInUp").removeClass("animate__slideOutDown");
      }, (i, el) => {
        el.children(".pcleft_link").removeClass("animate__slideInUp").addClass("animate__slideOutDown");
      });
      // pc端右边动画效果
      utils.handleScroll($(".js_index_activity .ac_one"), (i, el) => {
        setTimeout(() => {
          el.children(".one_link").removeClass("vishide").addClass("animate__slideInUp").removeClass("animate__slideOutDown");
        }, i * 100);
      }, (i, el) => {
        setTimeout(() => {
          el.children(".one_link").removeClass("animate__slideInUp").addClass("animate__slideOutDown");
        }, i * 100);
      },50);
    },
    removeVishide() {
      let w = $(window).width();
      if (w < 960) {
        $(".js_index_activity .pcleft_link,.js_index_activity .one_link").removeClass("vishide");
      } else {
        $(".js_index_activity .pcleft_link,.js_index_activity .one_link").addClass("vishide");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.activity {
  padding: 60px;
  .title {
    font-size: 40px;
    color: #333333;
    line-height: 56px;
    text-align: center;
    font-weight: bold;
  }
  .mob_small {
    display: flex;
    align-items: center;
    margin-top: 24px;
    .small_img {
      width: 139px;
      height: 78px;
    }
    .small_content {
      margin-left: 24px;
      min-width: 0;
      .small_desc {
        font-size: 26px;
        color: #333333;
        line-height: 37px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .small_date {
        font-size: 22px;
        color: #666666;
        line-height: 30px;
        margin-top: 11px;
      }
    }
  }
  .mob_more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #DBE1EA;
    span {
      font-size: 24px;
      color: #666666;
    }
    i {
      margin-left: 13px;
      font-size: 26px;
      color: #666666;
    }
  }
  .ac_box {
    .ac_pcleft {
      display: block;
      margin-top: 40px;
      a {
        display: flex;
        justify-content: flex-start;
      }
      .left_img {
        width: 267px;
        height: 150px;
      }
      .mob_right {
        flex: 1;
        min-width: 0;
        margin-left: 16px;
        .left_title {
          font-size: 26px;
          color: #333333;
          line-height: 37px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .left_desc {
          font-size: 23px;
          color: #666666;
          line-height: 32px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          margin-top: 8px;
          height: 64px;
        }
        .left_date {
          font-size: 20px;
          color: #666666;
          line-height: 28px;
          margin-top: 13px;
        }
      }
    }
    .ac_ul {
      margin-top: 8px;
      .ac_one {
        &:nth-child(3) {
          display: none;
        }
        a {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 32px;
        }
        .one_img {
          width: 267px;
          height: 150px;
        }
        .one_content {
          flex: 1;
          margin-left: 16px;
          min-width: 0;
          .one_title {
            font-size: 26px;
            color: #333333;
            line-height: 37px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .one_desc {
            font-size: 23px;
            color: #666666;
            line-height: 32px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-top: 8px;
            height: 64px;
          }
          .one_date {
            font-size: 20px;
            color: #666666;
            line-height: 28px;
            margin-top: 13px;
          }
        }
      }
    }
  }
  .more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }
}
.padbot0{
  padding-bottom: 0;
}
@media (min-width: 750px) and (max-width: 960px) {
  .activity {
    padding: 60px;
    .title {
      font-size: 32px;
      color: #333333;
      line-height: 45px;
      text-align: center;
      font-weight: bold;
    }
    .mob_small {
      display: flex;
      align-items: center;
      margin-top: 24px;
      padding: 0 72px;
      .small_img {
        width: 139px;
        height: 78px;
      }
      .small_content {
        margin-left: 24px;
        min-width: 0;
        .small_desc {
          font-size: 20px;
          color: #333333;
          line-height: 28px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 56px;
        }
        .small_date {
          font-size: 16px;
          color: #666666;
          line-height: 22px;
          margin-top: 0;
        }
      }
    }
    .mob_more {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #DBE1EA;
      span {
        font-size: 16px;
        color: #666666;
      }
      i {
        margin-left: 12px;
        font-size: 14px;
        color: #666666;
      }
    }
    .ac_box {
      margin-top: 40px;
      .ac_pcleft {
        display: block;
        margin-top: 16px;
        a {
          background-color: #FFFFFF;
          display: flex;
          justify-content: flex-start;
        }
        .left_img {
          width: 264px;
          height: 148px;
        }
        .mob_right {
          flex: 1;
          min-width: 0;
          margin-left: 24px;
          margin-right: 24px;
          .left_title {
            font-size: 18px;
            color: #333333;
            line-height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 16px;
          }
          .left_desc {
            font-size: 16px;
            color: #666666;
            line-height: 22px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-top: 8px;
            height: 44px;
          }
          .left_date {
            font-size: 16px;
            color: #666666;
            line-height: 22px;
            margin-top: 17px;
          }
        }
      }
      .ac_ul {
        margin-top: 0;
        .ac_one {
          &:nth-child(3) {
            display: none;
          }
          a {
            background-color: #FFFFFF;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 16px;
          }
          .one_img {
            width: 264px;
            height: 148px;
          }
          .one_content {
            flex: 1;
            margin-left: 24px;
            margin-right: 24px;
            min-width: 0;
            .one_title {
              font-size: 18px;
              color: #333333;
              line-height: 25px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-top: 16px;
            }
            .one_desc {
              font-size: 16px;
              color: #666666;
              line-height: 22px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              margin-top: 8px;
              height: 44px;
            }
            .one_date {
              font-size: 16px;
              color: #666666;
              line-height: 22px;
              margin-top: 17px;
            }
          }
        }
      }
    }
    .more {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 60px;
    }
  }
}
@media (min-width: 961px) {
  .activity {
    padding: 60px;
    .title {
      font-size: 32px;
      line-height: 45px;
    }
    .mob_small {
      display: none !important;
    }
    .mob_more {
      display: none !important;
    }
    .ac_box {
      display: flex !important;
      align-items: flex-start;
      justify-content: center;
      margin-top: 40px;
      .ac_pcleft {
        display: block;
        width: 588px;
        margin-top: 0;
        a {
          background-color: #FFFFFF;
          display: inline-block;
          width: 100%;
          height: 100%;
          &:hover {
            box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.07);
            .left_img {
              transform: scale(1.1);
            }
            .mob_right {
              .left_title {
                color: #0064EB;
              }
            }
          }
        }
        .left_img_box {
          overflow: hidden;
        }
        .left_img {
          width: 100%;
          height: 332px;
          transition: transform .5s;
        }
        .mob_right {
          flex: auto;
          min-width: 0;
          margin-left: 0;
          .left_title {
            font-size: 18px;
            color: #333333;
            line-height: 25px;
            padding: 16px 24px 0 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .left_desc {
            padding: 0 24px;
            font-size: 15px;
            color: #666666;
            line-height: 21px;
            margin-top: 12px;
            height: 63px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
          .left_date {
            font-size: 14px;
            color: #666666;
            line-height: 20px;
            padding: 14px 24px 25px 24px;
            margin-top: 0;
          }
        }
      }
      .ac_ul {
        margin-top: 0;
        margin-left: 24px;
        .ac_one {
          &:nth-child(3) {
            display: block;
          }
          margin-top: 16px;
          width: 588px;
          height: 158px;
          &:first-child {
            margin-top: 0;
          }
          a {
            background-color: #FFFFFF;
            width: 100%;
            height: 158px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0;
            &:hover {
              box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.07);
              .one_img {
                transform: scale(1.1);
              }
              .one_content {
                .one_title {
                  color: #0064EB;
                }
              }
            }
          }
          .one_img_box {
            overflow: hidden;
          }
          .one_img {
            width: 282px;
            height: 158px;
            transition: transform .5s;
          }
          .one_content {
            flex: 1;
            padding: 0 24px;
            margin-left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .one_title {
              font-size: 18px;
              line-height: 25px;
            }
            .one_desc {
              font-size: 14px;
              line-height: 20px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              margin-top: 10px;
              height: 58px;
            }
            .one_date {
              font-size: 12px;
              line-height: 17px;
              margin-top: 14px;
            }
          }
        }
      }
    }
    .more {
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-top: 60px;
    }
  }
}
</style>
