<template>
  <div class="feedback">
    <p class="title">客户反馈</p>
    <ul class="fb_box">
      <li class="fb_one" v-for="item in feedback.feedBackList" :key="item.moduleId">
        <img class="one_img" :src="utils.setOssUrl(item.picture)" alt="">
        <p class="one_desc">{{ item.content }}</p>
        <p class="one_post">{{ item.customer }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import utils from "@/utils/utils";

export default {
  name: "feedback",
  props: {
    feedback: {
      type: Object,
      default: () => {
        return {
          feedBackList: []
        };
      }
    }
  },
  data() {
    return {
      utils
    };
  },
  created() {
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.feedback {
  background-color: #FFFFFF;
  padding: 0 60px 70px;
  .title {
    font-size: 40px;
    color: #333333;
    line-height: 56px;
    padding-top: 60px;
    text-align: center;
    font-weight: bold;
  }
  .fb_box {
    margin-top: 10px;
    &:last-child {
      border-bottom: 1px solid #EEEEEE;
      padding-bottom: 40px;
    }
    .fb_one {
      margin-top: 40px;
      .one_img {
        width: 100%;
        height: 180px;
      }
      .one_desc {
        font-weight: bold;
        font-size: 28px;
        color: #333333;
        line-height: 40px;
        margin-top: 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .one_post {
        font-size: 22px;
        color: #333333;
        line-height: 30px;
        margin-top: 36px;
      }
    }
  }
}
@media (min-width: 750px) and (max-width: 960px) {
  .feedback {
    background-color: #FFFFFF;
    padding: 60px 0 80px;
    .title {
      font-size: 32px;
      color: #333333;
      line-height: 45px;
      padding-top: 0;
      text-align: center;
    }
    .fb_box {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
      .fb_one {
        margin-top: 0;
        width: 264px;
        margin-left: 24px;
        &:first-child {
          margin-left: 0;
        }
        .one_img {
          width: 100%;
          height: 75px;
        }
        .one_desc {
          font-size: 17px;
          color: #333333;
          line-height: 24px;
          margin-top: 16px;
          height: 72px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
        .one_post {
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          margin-top: 16px;
        }
      }
    }
  }
}
@media (min-width: 961px) {
  .feedback {
    background-color: #FFFFFF;
    padding: 60px 0 80px;
    .title {
      font-size: 32px;
      color: #333333;
      line-height: 45px;
      padding-top: 0;
      text-align: center;
    }
    .fb_box {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
      .fb_one {
        margin-top: 0;
        width: 384px;
        margin-left: 24px;
        &:first-child {
          margin-left: 0;
        }
        .one_img {
          width: 100%;
          height: 110px;
        }
        .one_desc {
          font-size: 18px;
          color: #333333;
          line-height: 25px;
          margin-top: 15px;
          height: 50px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        .one_post {
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          margin-top: 16px;
        }
      }
    }
  }
}
</style>
