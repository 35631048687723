<template>
  <div class="worth js_worth">
    <div class="worth_box">
      <p class="title">{{ worth.worthTitle }}</p>
      <p class="desc">{{ worth.worthSubtitle }}</p>
      <div class="box" :class="{box_two:index%2!==0}" v-for="(item,index) in worth.worthPoint" :key="item.worthId">
        <div class="b_left animate__animated">
          <p class="left_title">{{ item.worthPointTitle }}</p>
          <p class="left_desc">{{ item.description }}</p>
        </div>
        <img class="b_right animate__animated" :src="utils.setOssUrl(item.picture)" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import utils from "@/utils/utils";

export default {
  name: "worth",
  props: {
    worth: {
      type: Object,
      default: () => {
        return {
          worthSubtitle: "",
          worthTitle: "",
          worthPoint: []
        };
      }
    }
  },
  data() {
    return {
      utils,
    };
  },
  watch: {
    worth() {
      this.removeAnimate()
      window.onresize = () => {
        this.removeAnimate()
      };
    }
  },
  methods: {
    handleScroll() {
      utils.handleScroll($(".js_worth .box"),(i,el)=>{
        if (el.hasClass("box_two")) {
          el.children(".b_left").removeClass("vishide").removeClass("animate__fadeOutRight").addClass(" animate__fadeInRight");
          el.children(".b_right").removeClass("vishide").removeClass("animate__fadeOutLeft").addClass(" animate__fadeInLeft");
        } else {
          el.children(".b_left").removeClass("vishide").removeClass("animate__fadeOutLeft").addClass(" animate__fadeInLeft");
          el.children(".b_right").removeClass("vishide").removeClass("animate__fadeOutRight").addClass(" animate__fadeInRight");
        }
      },(i,el)=>{
        if (el.hasClass("box_two")) {
          el.children(".b_left").removeClass("animate__fadeInRight").addClass("animate__fadeOutRight");
          el.children(".b_right").removeClass("animate__fadeInLeft").addClass("animate__fadeOutLeft");
        } else {
          el.children(".b_left").removeClass("animate__fadeInLeft").addClass("animate__fadeOutLeft");
          el.children(".b_right").removeClass("animate__fadeInRight").addClass("animate__fadeOutRight");
        }
      })
    },
    removeAnimate(){
      let w = $(window).width();
      if (w < 720) {
        $(".js_worth .b_left").removeClass("animate__fadeOutRight animate__fadeInRight animate__fadeOutLeft animate__fadeInLeft")
        $(".js_worth .b_right").removeClass("animate__fadeOutRight animate__fadeInRight animate__fadeOutLeft animate__fadeInLeft")
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.handleScroll);
    });
  }
};
</script>

<style scoped lang="scss">
.worth {
  overflow: hidden;
  background-color: #FFFFFF;
  .worth_box {
    padding: 60px;
  }
  .title {
    font-size: 40px;
    color: #333333;
    line-height: 56px;
    text-align: center;
    font-weight: bold;
  }
  .desc {
    font-size: 26px;
    color: #666666;
    line-height: 37px;
    margin-top: 12px;
    text-align: center;
  }
  .box {
    margin-top: 78px;
    .b_left {
      .left_title {
        font-size: 28px;
        color: #333333;
        line-height: 1;
        border-left: 8px solid #0064EB;
        padding-left: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: bold;
      }
      .left_desc {
        font-size: 23px;
        color: #666666;
        line-height: 32px;
        margin-top: 19px;
        white-space: pre-wrap;
      }
    }
    .b_right {
      width: 100%;
      height: 316px;
      margin-top: 34px;
    }
  }
  .box_two {
    margin-top: 81px;
  }
}
@media (min-width: 750px) and (max-width: 960px) {
  .worth {
    background-color: #FFFFFF;
    .worth_box {
      padding: 60px;
    }
    .title {
      font-size: 32px;
      color: #333333;
      line-height: 45px;
      text-align: center;
      font-weight: bold;
    }
    .desc {
      font-size: 22px;
      color: #666666;
      line-height: 30px;
      margin-top: 16px;
      text-align: center;
    }
    .box {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      .b_left {
        width: 384px;
        margin-right: 48px;
        .left_title {
          font-size: 20px;
          color: #333333;
          line-height: 24px;
          border-left: none;
          padding-left: 0;
        }
        .left_desc {
          font-size: 16px;
          color: #666666;
          line-height: 22px;
          margin-top: 19px;
        }
      }
      .b_right {
        width: 408px;
        height: 204px;
        margin-top: 0;
      }
    }
    .box_two {
      margin-top: 60px;
      flex-direction: row-reverse;
      .b_left {
        margin-right: 0;
        margin-left: 60px;
      }
    }
  }
}
@media (min-width: 961px) {
  .worth {
    .worth_box {
      padding: 91px 0 60px 0;
      width: 1200px;
      margin: 0 auto;
    }
    .title {
      font-size: 32px;
      color: #333333;
      line-height: 45px;
    }
    .desc {
      font-size: 24px;
      color: #666666;
      line-height: 33px;
      margin-top: 18px;
    }
    .box {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      .b_left {
        width: 552px;
        margin-right: 60px;
        .left_title {
          font-size: 26px;
          color: #333333;
          line-height: 37px;
          border-left: none;
          padding-left: 0;
        }
        .left_desc {
          font-size: 18px;
          color: #666666;
          line-height: 25px;
          margin-top: 24px;
        }
      }
      .b_right {
        width: 588px;
        height: 294px;
        margin-top: 0;
      }
    }
    .box_two {
      margin-top: 60px;
      flex-direction: row-reverse;
      .b_left {
        margin-right: 0;
        margin-left: 60px;
      }
    }
  }
}
</style>
